.registerLogoWrapper {
  border-bottom: 2px solid var(--color-orange1);
}
.registerLogo {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  max-width: 135px;
}
.registerLogout {
  cursor: pointer;
  float: right;
  margin: 10px 10px 0 0;
}

.h1 {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.card {
  border: 1px solid var(--color-dark-gray3) !important;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 10px;
}

.label {
  color: var(--color-dark-blue1);
  font-size: 0.875rem;
  font-weight: 500;
}

.checkText {
  font-size: 0.8125rem;
}

.inputGroupText {
  background-color: transparent;
  color: var(--color-dark-blue1);
  /*font-size: 1.125rem;*/
  font-weight: 500;
}

.bigChoiceBox {
  border: 1px solid var(--color-dark-gray3);
  border-radius: 5px;
  cursor: pointer;
  padding: 9px 0;
}
.bigChoiceBox span {
  display: block;
  text-align: center;
}
.bigChoiceBoxTop {
  color: var(--color-light-gray4);
  font-size: 0.4375rem;
}
.bigChoiceBoxMiddle {
  color: var(--color-dark-blue1);
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1;
}
.bigChoiceBoxBottom {
  color: var(--color-dark-blue1);
  font-size: 0.5625rem;
  font-weight: 500;
}
.bigChoiceBoxCheckWrapper {
  max-width: 137px;
}
.bigChoiceBoxCheck {
  border: 1px solid var(--color-dark-blue1);
  border-radius: 5px;
  color: var(--color-dark-blue1);
  width: 39px;
  height: 40px;
  margin: 2px auto 5px;
}
.checkmark {
  color: var(--color-orange1);
  display: inline-block;
  font-size: 1.4rem;
  margin-top: 8px;
}
.bigChoiceBoxCheckText {
  color: var(--color-orange1);
  display: inline-block;
  font-size: 0.875rem;
  margin-top: 8px;
  font-weight: 500;
  padding-top: 9px;
}
.bigX {
  color: var(--color-orange1);
  font-size: 1.5rem;
  font-weight: 500;
}

.smallChoice {
  border: 1px solid var(--color-dark-gray3);
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  padding: 6px 0;
  margin: 0 5px 10px;
  max-width: 112px;
}

.choiceBoxActive {
  background-color: var(--color-dark-blue1);
  border: 1px solid var(--color-dark-blue1);
  color: #fff;
}
.choiceBoxActive span {
  color: #fff;
}
.choiceBoxActive .bigChoiceBoxCheck {
  border: 1px solid #fff;
}
.choiceBoxActive .bigX,
.choiceBoxActive .bigChoiceBoxCheckText {
  color: var(--color-orange1);
}

.smallLabel {
  font-size: 0.75rem;
}

.emailIcon {
  display: block;
  margin: 80px auto;
}
.emailText {
  display: block;
  max-width: 320px;
  font-size: 1rem;
  margin: 0 auto 30px;
  text-align: center;
}
.emailResend {
  display: block;
  border: 0 none;
  color: #323232;
  font-size: 0.875rem;
  margin: 0 auto;
  padding: 0;
}

.regCompleteText {
  font-size: 1.5rem;
}
.regCompleteSubtext {
  color: var(--color-dark-blue1);
  font-size: 0.875rem;
  width: 100%;
  max-width: 300px;
}

.blueButton {
    display: block;
    color: #FFFFFF;
    background-color: var(--color-dark-blue1);
    border: 0 none;
    border-radius: 10px;
    font-size: 1rem;
    margin: 25px auto;
    width: 50%;
    padding: 10px;
}

@media (max-width: 767px) {
  .card {
    border: 0 none !important;
  }
  .h1,
  .h2,
  .card {
    padding: 0 35px;
  }
}
